import { Typography } from "@mui/material";
import { getIntersections } from "./intersections"


export const getClassToolTipTitle = (clss, classesComposition) => {
    var text = clss + ":" + Array.from(new Set(Object.entries(classesComposition[clss]).map(([k, v]) => v))).filter(e => e !== '')
        .reduce((acc, cur) => acc = acc + " " + cur + ", ", "")
    return <Typography variant="p" style={{ color: "primary" }}>{text.slice(0, -2)}</Typography>;
}


export const getShiftToolTipTitle = (day_idx, shift_idx, scheduleFound, classesComposition) => {
    var intersections = getIntersections(day_idx, shift_idx, scheduleFound, classesComposition);
    var text = intersections.length > 0 ? intersections.reduce((acc, cur) => acc = acc + " " + cur, "intersezioni: ").trim() : 'No intersezioni'
    return <Typography variant="h6" style={{ color: "primary" }}>{text}</Typography>;
}
