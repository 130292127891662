import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home"
import AskForClassesCompositionExcelFile from "./AskForClassesCompositionExcelFile";
import AskForSolutionExcelFile from "./AskForSolutionExcelFile";
import ConfigureSolver from "./ConfigureSolver"
import Response from "./Response"
import HelpAskForExcelFile from "./HelpAskForExcelFile";
import FirstStep from "./FirstStep";
import NotFound from "./NotFound"



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/first-step" element={<FirstStep/>} />
        <Route path="/upload-classes-composition" element={<AskForClassesCompositionExcelFile/>} />
        <Route path="/upload-solution" element={<AskForSolutionExcelFile/>}/>
        <Route path="/configuration" element={<ConfigureSolver />} />
        <Route path="/solution" element={<Response />} />
        <Route path="/help-excel" element={<HelpAskForExcelFile />} />
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
