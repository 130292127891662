const filterConfig = {
    ignoreCase: true,
    ignoreAccents: false,
    trim: true,
    matchFrom: 'start'
};


const styles = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
            ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
            : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { filterConfig, styles };