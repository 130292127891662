import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Tooltip, Button, Typography, Paper, Stack } from "@mui/material";
import { getItemStyle, getListStyle, onDragEnd } from "./dndUtils"
import { getClassToolTipTitle, getShiftToolTipTitle } from "./ToolTipsUtils"
import { ResponsiveContainer, BarChart, Bar, Legend, Tooltip as REChartsToolTip, CartesianGrid, XAxis, YAxis } from "recharts"
import { getDaysAtSchoolFrequencies } from "./daysAtSchoolChartsUtils"
import ChartTooltip from "./ChartToolTip"
import DownloadIcon from '@mui/icons-material/Download';
import WriteExcelFile from "./WriteExcelFile"
import AddIcon from '@mui/icons-material/Add';
import Error from "./Error";
import PaperContainer from "./PaperContainer";

function ShowAndEditSolution(props) {
    const [scheduleFound, setScheduleFound] = useState(props.scheduleFound)
    try {
        let classesComposition = props.classesComposition
        let teachers = props.teachers
        let { daysAtSchoolFrequencies, daysAtSchoolFrequenciesTeachers } = getDaysAtSchoolFrequencies(scheduleFound, classesComposition, teachers)

        return (
            <Stack
                direction="column"
                justifyContent="center"
                spacing={1}
                margin={2}>
                <Typography variant="h6" align="center">Puoi trascinare le classi per modificare la pianificazione</Typography>
                <Stack direction="row"
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    flexWrap={"wrap"}
                >
                    <DragDropContext onDragEnd={(result) => { onDragEnd(result, scheduleFound, setScheduleFound) }}>
                        {
                            scheduleFound.map((day, day_idx) =>

                                <Stack
                                    key={day_idx + 1}
                                    direction="column"
                                    justifyContent="center"
                                    alignItems={"center"}
                                    padding={2}
                                >
                                    <Paper elevation={20}>
                                        <Stack
                                            direction="column"
                                            justifyContent="center"
                                            alignItems={"center"}
                                            padding={2}
                                            width={"15vw"}>
                                            <Typography sx={{ display: "flex", whiteSpace: "nowrap" }} variant="h6" justifyContent={"center"}> Giorno {day_idx + 1}</Typography>
                                            {day.map((shift, shift_idx) =>
                                                // the id of the droppable is: day, shift
                                                <Stack
                                                    key={`${day_idx},${shift_idx}`}
                                                    direction="row"
                                                    flex="wrap"
                                                    padding={2}>
                                                    <Droppable droppableId={`${day_idx},${shift_idx}`} direction="horizontal">
                                                        {(provided, snapshot) => (
                                                            <Tooltip
                                                                placement="top"
                                                                arrow
                                                                key={`${day_idx},${shift_idx}`}
                                                                title={getShiftToolTipTitle(day_idx, shift_idx, scheduleFound, classesComposition)}>
                                                                <Paper
                                                                    elevation={20}
                                                                    ref={provided.innerRef}
                                                                    style={getListStyle(snapshot.isDraggingOver, snapshot.draggingFromThisWith, day_idx, shift_idx, scheduleFound, classesComposition)}
                                                                    {...provided.droppableProps}
                                                                >
                                                                    <Stack direction="row"
                                                                        justifyContent="flex-start"
                                                                        flex="wrap"
                                                                        flexWrap={"wrap"}
                                                                        padding={1}
                                                                        width={"15vw"}>
                                                                        {shift.map((clss, clss_idx) =>
                                                                            // the id of the Draggable is the class name
                                                                            <Draggable key={clss} draggableId={clss} index={clss_idx}>
                                                                                {(provided, snapshot) => (
                                                                                    <Tooltip
                                                                                        placement={"bottom"}
                                                                                        arrow
                                                                                        key={clss}
                                                                                        title={getClassToolTipTitle(clss, classesComposition)}>
                                                                                        <Paper
                                                                                            elevation={20}
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps.style
                                                                                            )}
                                                                                        >
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent: "space-around",
                                                                                                    whiteSpace: "nowrap"
                                                                                                }}
                                                                                            >
                                                                                                <Typography variant="p" style={{ display: "inline-block" }}>
                                                                                                    {clss}
                                                                                                </Typography>
                                                                                            </div>
                                                                                        </Paper>
                                                                                    </Tooltip>
                                                                                )}
                                                                            </Draggable>
                                                                        )}
                                                                    </Stack>
                                                                    {provided.placeholder}
                                                                </Paper>
                                                            </Tooltip>)}
                                                    </Droppable>
                                                </Stack>
                                                // </div>
                                            )}
                                            <Button
                                                sx={{ margin: 2 }}
                                                variant="contained"
                                                onClick={() => {
                                                    var newSchedule = [...scheduleFound]
                                                    newSchedule[day_idx].push([])
                                                    setScheduleFound(newSchedule)
                                                }}
                                                component="label">
                                                <AddIcon />
                                            </Button>
                                        </Stack>
                                    </Paper>
                                </Stack>
                            )
                        }
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems={"center"}
                            padding={2}>
                            <Paper elevation={20}>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems={"center"}
                                    padding={2}
                                    width={"15vw"}>
                                    <Typography sx={{ display: "flex" }} variant="p" textAlign={"center"}>Aggiungi un giorno</Typography>
                                    <Button
                                        sx={{ margin: 2, height: "fit-content" }}
                                        variant="contained"
                                        onClick={() => {
                                            var newSchedule = [...scheduleFound]
                                            newSchedule.push([[]])
                                            setScheduleFound(newSchedule)
                                        }}
                                        component="label">
                                        <AddIcon />
                                    </Button>
                                </Stack>
                            </Paper>
                        </Stack>
                    </DragDropContext>
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems={"center"}
                    spacing={2}>
                    <Button
                        sx={{ margin: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                            WriteExcelFile(scheduleFound, classesComposition, daysAtSchoolFrequenciesTeachers);
                        }}
                    >
                        Salva
                        <DownloadIcon />
                    </Button>
                    <PaperContainer alignContent="center" maxWidth="lg" minHeight="0">
                        <div style={{ width: "50vw", height: "50vh", marginBottom: "2%" }}>
                            <Typography align="center" variant="h5">Quanti giorni vengono a scuola i docenti?</Typography>
                            <ResponsiveContainer height={"100%"} width={"95%"}>
                                <BarChart data={daysAtSchoolFrequencies}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="numero di giorni" />
                                    <YAxis />
                                    <REChartsToolTip content={<ChartTooltip daysAtSchoolFrequenciesTeachers={daysAtSchoolFrequenciesTeachers} />} />
                                    <Legend />
                                    <Bar dataKey="numero di docenti" fill="#2196f3" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </PaperContainer>
                </Stack>
            </Stack>
        );
    } catch (error) {
        return <Error error={error.toString()}></Error>
    }
}
export default ShowAndEditSolution;