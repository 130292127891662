import { Grid, Typography, TextField } from "@mui/material"

function IntInput(props) {

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
                <Typography variant="p">{props.textBeside}</Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    defaultValue={props.defaultValue}
                    onChange={(e) => { props.onChange(parseInt(e.target.value)) }}
                    type="number"
                    InputProps={{ inputProps: { min: props.min, max: props.max, style: { textAlign: 'center' } } }} />
            </Grid>
        </Grid>
    )
}

export default IntInput