import { Typography, Button } from "@mui/material"
import * as XLSX from 'xlsx'
import { readClassesCompositionFromExcel } from "./ReadExcel"
import { useState } from "react"
import CheckIcon from '@mui/icons-material/Check';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { Link } from "react-router-dom";
import Error from "./Error"
import PaperContainer from "./PaperContainer"

function AskForClassesCompositionExcelFile() {
    const [goOnDisabled, setGoOnDisabled] = useState(true)
    const [fileName, setFileName] = useState("nessun file attualmente scelto")
    const [fileIsOk, setFileIsOk] = useState(false)
    const [classesComposition, setClassesComposition] = useState({})
    try {
        const handleFileChange = (e) => {
            const file = e.target.files[0];
            var name = file.name;
            const reader = new FileReader();
            reader.onload = (evt) => { // evt = on_file_select event
                try {
                    const bstr = evt.target.result;
                    const wb = XLSX.read(bstr, { type: 'binary' });
                    if (wb.hasOwnProperty("Workbook")) { // check if it's a Excel file
                        setFileIsOk(true)
                        setGoOnDisabled(false)
                        var cc = readClassesCompositionFromExcel(wb);
                        setClassesComposition(cc)
                    } else {
                        setFileIsOk(false)
                        setGoOnDisabled(true)
                    }

                    setFileName(name)
                } catch (error) {
                    console.log(error)
                    setFileIsOk(false)
                    setGoOnDisabled(true)
                }
            };
            reader.readAsBinaryString(file);
        }

        let icon;
        if (fileIsOk) {
            icon = <CheckIcon sx={{ color: "green" }}></CheckIcon>
        } else {
            icon = <DangerousIcon sx={{ color: "red" }}></DangerousIcon>
        }

        return (
            <PaperContainer>
                <Typography variant="h6" textAlign={"center"}>Carica la composizione dei consigli di classe</Typography>
                <Button variant="contained" component="label">scegli un file
                    <input type="file" name="input.xlsx" hidden onChange={handleFileChange} />
                </Button>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <Typography variant="p">{fileName}</Typography>
                    {icon}
                </div>
                <Button component={Link}
                    to="/configuration"
                    state={{ "classesComposition": classesComposition }}
                    variant="contained"
                    disabled={goOnDisabled}>
                    avanti
                </Button>
                <Typography variant="p">La composizione dei consigli di classe dovrà essere in un file Excel.
                    <br></br>
                    <a href="esempio_consigli_di_classe.xlsx" download>Qui</a> trovi un file di esempio.
                    <br></br>
                    <Link to="/help-excel">Qui</Link> trovi una guida su come deve essere strutturato il file excel.
                </Typography>
            </PaperContainer>
        );
    } catch (error) {
        return <Error error={error.toString()}></Error>
    }
}

export default AskForClassesCompositionExcelFile