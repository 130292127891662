export function getIntersections(day_idx, shift_idx, scheduleFound, classesComposition) {
    if (scheduleFound[day_idx].length <= 0)
        return []
    const classes = scheduleFound[day_idx][shift_idx]
    if (classes.length < 2) {
        return []
    } else {
        let common_teachers = new Set();
        for (let i = 0; i < classes.length; i++) { 
            for (let j = i + 1; j < classes.length; j++) {
                // console.log(classesComposition[classes[i]])
                const i_teachers = new Set(Object.entries(classesComposition[classes[i]])
                    .map(([k, v]) => v).filter(v => v !== ''))
                const j_teachers = new Set(Object.entries(classesComposition[classes[j]])
                    .map(([k, v]) => v).filter(v => v !== ''))
                let itx = Array.from(new Set([...i_teachers].filter(x => j_teachers.has(x))))
                for (let teacher of itx) {
                    common_teachers.add(teacher)
                }
            }
        }
        return Array.from(common_teachers);
    }
}


export function pickColorBasedOnIntersections(day_idx, shift_idx, scheduleFound, classesComposition) {

    var intersections = getIntersections(day_idx, shift_idx, scheduleFound, classesComposition)

    switch (intersections.length) {
        case 0:
            return "lightgreen";
        case 1:
            return "#ebc934";
        case 2:
            return "#eb8f34"
        case 3:
            return "#eb7134"
        case 4:
            return "#eb4f34"
        case 5:
            return "#eb3434"
        default:
            return "#f50505"
    }
}
