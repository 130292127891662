import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PaperContainer from "./PaperContainer";
import { Radio, Grid, Button, Typography, RadioGroup, FormControl, FormControlLabel } from "@mui/material"
import Error from "./Error";

function FirstStep() {

    const navigate = useNavigate()
    const [choice, setChoice] = useState("classesComposition")
    try {
        const goOn = (e) => {
            if (choice === "classesComposition") {
                navigate("/upload-classes-composition")
            } else {
                navigate("/upload-solution")
            }
        }
        return (
            <PaperContainer alignContent="center">
                <Typography variant="h6">Cosa devi fare?</Typography>
                <Grid container direction="row" alignItems={"center"}>
                    <Grid item xs={12}>
                        <FormControl>
                            <RadioGroup
                                defaultValue="classesComposition"
                                name="radio-buttons-group"
                                onChange={(e) => {
                                    setChoice(e.target.value);
                                }}
                            >
                                <Grid container direction="column">
                                    <Grid item xs={6}>
                                        <FormControlLabel value="classesComposition" control={<Radio />} label={<Typography variant="p">Devo pianificare i consigli di classe</Typography>} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel value="solution" control={<Radio />} label={<Typography variant="p">Ho già una pianificazione e la voglio consultare e modificare</Typography>} />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Button variant="contained" onClick={goOn}>
                    avanti
                </Button>
            </PaperContainer>
        )
    } catch (error) {
        console.log(error)
        return <Error error={error.toString()}></Error>
    }
}
export default FirstStep;