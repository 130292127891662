/**
 * takes classescomposition object returning two arrays:
 *  -teachers
 *  -subjects
 * @param {the excel workbook} wb 
 */
function getTeachersAndSubjects(classesComposition){
    var s = new Set(); // subjects
    var t = new Set(); // teachers
    for (let section of Object.keys(classesComposition))    
      for (var clss of Object.keys(classesComposition[section])){
        for (var subj of Object.keys(classesComposition[section][clss])){
          s.add(subj)
          if(classesComposition[section][clss][subj]!=='')
            t.add(classesComposition[section][clss][subj])
        }
      }
    let subjectsArray = Array.from(s);
    let teachersArray = Array.from(t);   
    return {
        teachers:teachersArray,
        subjects:subjectsArray
    };
}

export default getTeachersAndSubjects;