import { Typography } from "@mui/material";
import PaperContainer from "./PaperContainer";

function Error(props) {
    console.log(props.error)
    return (
        <PaperContainer>
            <Typography variant="h6">Ops, qualcosa è andato storto. Torna indietro e riprova</Typography>
            <Typography variant="p">{props.error?props.error.toString():""}</Typography>
        </PaperContainer>
    )
}


export default Error;