import ShowAndEditSolution from "./ShowAndEditSolution";
import Error from "./Error";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
function Response() {
    const location = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        if (location.state === null) {
                navigate(
                    "/"
                );
        }
    }, [location, navigate]);

    if (location['state'] !== null) {
        if ('error' in location['state']) {
            return <Error error={location['state']['error']}></Error>
        } else {
            try {

                let classesComposition = location['state']['classesComposition']
                let scheduleFound = location['state']['solution']
                let teachers = location['state']['teachers']
                return <ShowAndEditSolution
                    classesComposition={classesComposition}
                    scheduleFound={scheduleFound}
                    teachers={teachers}
                />
            } catch (error) {
                return <Error error={error.toString()}></Error>
            }
        }
    }
}

export default Response;