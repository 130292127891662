import { Link } from "react-router-dom";
import PaperContainer from "./PaperContainer";
import { Typography } from "@mui/material";

function NotFound() {
    return <PaperContainer>
        <Typography variant="h6" textAlign={"center"}>Pagina inesistente, torna alla {" "}
            <Link to="/">pagina iniziale</Link>
        </Typography>

    </PaperContainer>
}

export default NotFound;