import { Typography, Paper } from "@mui/material";

const ChartToolTip = ({ active, payload, label, daysAtSchoolFrequenciesTeachers }) => {
    if (active && payload && payload.length) {
        let idx = payload[0]['payload']['numero di giorni']
        let ndoc = payload[0]['payload']['numero di docenti']
        let txt = daysAtSchoolFrequenciesTeachers[idx].reduce((acc, cur) => acc + cur + ", ", "")
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-around"
                }}
            >
                <Paper elevation={20} sx={{padding:2}}>
                    <Typography justifyContent={"center"} variant="p">{ndoc} docenti: </Typography>
                    <Typography variant="p">{txt.slice(0, -2)}</Typography>
                </Paper>
            </div>
        );
    }

    return null;
};


export default ChartToolTip;