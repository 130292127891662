const prod = {
    url: {
        SOLVER_URL: 'https://solve-problem-bgwa5v57nq-oa.a.run.app'
    }
};
const dev = {
    url: {
        SOLVER_URL: 'http://localhost:8080/'
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;