import { pickColorBasedOnIntersections } from "./intersections"


const itemStyleGrid = 6;
export const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: itemStyleGrid,
    margin: `${itemStyleGrid}px`,
    ...draggableStyle
    // margin: `${itemStyleGrid}px ${itemStyleGrid}px ${itemStyleGrid}px ${itemStyleGrid}px`,
    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",
    // styles we need to apply on draggables
});

const listSyleGrid = 8;
export const getListStyle = (isDraggingOver, draggableId, day_idx, shift_idx, scheduleFound, classesComposition) => ({
    background: isDraggingOver ? "#2196f3" : pickColorBasedOnIntersections(day_idx, shift_idx, scheduleFound, classesComposition),
    padding: listSyleGrid,
    display: 'flex',
    overflow: 'auto',
    margin:"4%"
    // margin: `${listSyleGrid}px ${listSyleGrid}px ${listSyleGrid}px ${listSyleGrid}px`,
    // margin:"5%",
});


const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};



const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


export function onDragEnd(result, scheduleFound, setScheduleFound) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
        return;
    }
    // const sInd = +source.droppableId;
    // const dInd = +destination.droppableId;
    const sDayandShift = source.droppableId.split(",");
    const sDay = parseInt(sDayandShift[0])
    const sShift = parseInt(sDayandShift[1])

    const dDayandShift = destination.droppableId.split(",");
    const dDay = parseInt(dDayandShift[0])
    const dShift = parseInt(dDayandShift[1])

    console.log("source: ", sDay, sShift)
    console.log("dest: ", dDay, dShift)
    if (sDay === dDay && sShift === dShift) {
        const items = reorder(scheduleFound[sDay][sShift], source.index, destination.index);
        const newSchedule = [...scheduleFound];
        newSchedule[sDay][sShift] = items;
        setScheduleFound(newSchedule);
    } else {
        const result = move(scheduleFound[sDay][sShift], scheduleFound[dDay][dShift], source, destination);
        const newSchedule = [...scheduleFound];
        newSchedule[sDay][sShift] = result[source.droppableId];
        newSchedule[dDay][dShift] = result[destination.droppableId];

        setScheduleFound(newSchedule.map(day => day.filter(s => s.length)).filter(day => day.length))
    }
}