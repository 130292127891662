import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom"
import CheckIcon from '@mui/icons-material/Check';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { readSolutionFromExcel } from "./ReadExcel"
import { useState } from "react";
import * as XLSX from 'xlsx'
import Error from "./Error";
import PaperContainer from "./PaperContainer";


function AskForSolutionExcelFile() {
    const [goOnDisabled, setGoOnDisabled] = useState(true)
    const [fileIsOk, setFileIsOk] = useState(false)
    const [fileName, setFileName] = useState("nessun file attualmente scelto")
    const [classesComposition, setClassesComposition] = useState([])
    const [scheduleFound, setScheduleFound] = useState([])
    const [teachers, setTeachers] = useState([])
    try {
        let icon;
        if (fileIsOk) {
            icon = <CheckIcon sx={{ color: "green" }}></CheckIcon>
        } else {
            icon = <DangerousIcon sx={{ color: "red" }}></DangerousIcon>
        }
        const handleFileChange = (e) => {
            const file = e.target.files[0];
            let name = file.name;
            const reader = new FileReader();
            reader.onload = (evt) => { // evt = on_file_select event
                try {
                    const bstr = evt.target.result;
                    const wb = XLSX.read(bstr, { type: 'binary' });
                    if (wb.hasOwnProperty("Workbook")) { // check if it's a Excel file
                        setFileIsOk(true)
                        setGoOnDisabled(false)
                        let rv = readSolutionFromExcel(wb);
                        setClassesComposition(rv['classesComposition'])
                        setScheduleFound(rv['scheduleFound'])
                        setTeachers(rv['teachers'])
                        console.log(rv)
                    } else {
                        setFileIsOk(false)
                        setGoOnDisabled(true)
                    }

                    setFileName(name)
                } catch (error) {
                    console.log(error)
                    setFileIsOk(false)
                    setGoOnDisabled(true)
                }
            };
            reader.readAsBinaryString(file);
        }

        let LinkToFile =
            <a href="pianificazione.xlsx" download>qui</a>

        return (
            <PaperContainer>
                <Typography variant="h6">Carica la pianificazione</Typography>
                <Button variant="contained" component="label">scegli un file
                    <input type="file" name="input.xlsx" hidden onChange={handleFileChange} />
                </Button>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <Typography variant="p">{fileName}</Typography>
                    {icon}
                </div>
                <Button component={Link} to="/solution" state={{ "classesComposition": classesComposition, "solution": scheduleFound, "teachers": teachers }} variant="contained" disabled={goOnDisabled}>
                    avanti
                </Button>
                <Typography variant="p">La pianificazione dovrà essere in un file Excel {" di cui "}{LinkToFile} {" trovi un esempio"}</Typography>
            </PaperContainer>
        )
    } catch (error) {
        return <Error error={error.toString()}></Error>
    }
}
export default AskForSolutionExcelFile;