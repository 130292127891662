import { Paper, Stack, Container, } from "@mui/material"
import Error from "./Error";

function PaperContainer({ maxWidth = "md",
                        alignContent = "center",
                        minHeight="100vh",
                        children }) {
    try {
        return (
            <Stack direction="column"
                justifyContent="center"
                alignItems="center"
                minHeight={minHeight}>
                <Container maxWidth={maxWidth}>
                    <Stack margin={"2%"} >
                        <Paper elevation={20}>
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems={alignContent}
                                spacing={4}
                                margin={"3%"}
                            >
                                {children}
                            </Stack>
                        </Paper>
                    </Stack>
                </Container>
            </Stack>
        )
    } catch (error) {
        console.log(error)
        return <Error error={error.toString()}></Error>
    }
}
export default PaperContainer;