import { Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material"
import Error from "./Error";

function Home() {
    const navigate = useNavigate()
    try {
        const goOn = (e) => {
            navigate("/first-step")
        }
        return (
            <div>
                <Stack margin="2%" alignItems={"center"} justifyContent="center" spacing={2} direction="column" minHeight={"100vh"} >
                    <img src="favicon_grande.png" height="200vh" alt="immagine d'esempio"></img>
                    <Typography variant="h4" >Pianifica CdC </Typography>
                    <Typography variant="h6" textAlign={"center"}>Pianifica con facilità i consigli di classe della tua scuola! <br></br>Svolgendo in parallelo riunioni di classi che non hanno docenti in comune!</Typography>
                    <Button variant="contained" onClick={goOn}>
                        Inizia
                    </Button>
                </Stack>
            </div>
        )
    } catch (error) {
        console.log(error)
        return <Error error={error.toString()}></Error>
    }
}
export default Home;