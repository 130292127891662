import * as XLSX from 'xlsx';
/**
 * takes the excel workbook and reads every sheet returning an object with all classes
 * compositions.
 * @param {the excel workbook} wb 
 */
export function readClassesCompositionFromExcel(wb) {

    const sheetNames = wb.SheetNames

    let classesComposition = {}

    for (let sheetName of sheetNames) {
        let ws = wb.Sheets[sheetName]
        let data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        let lines = data.split("\n");
        let classes = lines[0].split(",").slice(1);
        classesComposition[sheetName] = {}
        for (let c of classes) {
            classesComposition[sheetName][c] = {};
        }

        for (let line of lines.slice(1)) {
            let column = 1
            //console.log(line)
            let lineSplit = line.split(",")
            let subject = lineSplit[0];

            if (subject !== '') {
                for (let c of classes) {
                    classesComposition[sheetName][c][subject] = lineSplit[column] !== 'X' ? lineSplit[column].trim() : '';
                    column++;
                }
            }
        }
    }
    return classesComposition;
}


export function readSolutionFromExcel(wb) {
    const sheetNames = wb.SheetNames
    let classesComposition = {}
    let scheduleFound = []
    let teachers = new Set()
    let classesCompositionSheetName = sheetNames[0]
    let ws = wb.Sheets[classesCompositionSheetName]
    // read sheet with compositions:
    let data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
    let lines = data.split("\n")
    for (let line of lines) {
        let line_data = line.split(",")
        let class_ = line_data[0]
        let classTeachers = line_data.slice(1)
        classesComposition[class_] = classTeachers.filter(e => e !== '')
        for (let teacher of classTeachers) {
            if (teacher!=='')
                teachers.add(teacher)
        }
    }
    for (let sheetName of sheetNames.slice(1)) {
        ws = wb.Sheets[sheetName]
        let data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        let lines = data.split("\n")
        let day = []
        for (let line of lines) {
            let shift = line.split(",")
            day.push(shift.filter(e => e !== ''))
        }
        scheduleFound.push(day)
    }
    teachers = Array.from(teachers)
    return { scheduleFound, classesComposition, teachers }
}

