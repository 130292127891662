import { useLocation, useNavigate } from "react-router-dom";
import { Stack, Typography, Grid, FormControl, FormControlLabel, RadioGroup, Radio, Button, CircularProgress } from "@mui/material"
import getTeachersAndSubjects from "./getTeachersAndSubjects"
import { useState } from "react";
import MultiSelect from "./MultiSelect";
import IntInput from "./IntInput";
import axios from "axios";
import { config } from "./constants"
import Error from "./Error";
import PaperContainer from "./PaperContainer";

let abortController = new AbortController();
window.onpopstate = () => {
    abortController.abort();
}

function ConfigureSolver(props) {
    // state:
    const [subjectsToExclude, setSubjectsToExclude] = useState([])
    const [teachersToExclude, setTeachersToExclude] = useState([])
    const [intValue, setIntValue] = useState(5)
    const [maxPerDayOrNumberOfDays, setmaxPerDayOrNumberOfDays] = useState("chooseNumberOfDays")
    const [waitingForResponse, setWaitingForResponse] = useState(false)
    const navigate = useNavigate();

    try {
        const location = useLocation();
        // retrieve classesComposition and teachers and classes
        const classesComposition = location["state"]["classesComposition"];
        var { teachers, subjects } = getTeachersAndSubjects(classesComposition);
        teachers = teachers.map((e) => { return { value: e, label: e } })
        subjects = subjects.map((e) => { return { value: e, label: e } })

        const goOn = (e) => {
            setWaitingForResponse(true)
            abortController = new AbortController()
            axios.post(config.url.SOLVER_URL, {
                classesComposition: classesComposition,
                subjectsToExclude: subjectsToExclude.map((e) => e['value']),
                teachersToExclude: teachersToExclude.map((e) => e['value']),
                maxPerDayOrNumberOfDays: maxPerDayOrNumberOfDays,
                intValue: intValue,
            }, {
                signal: abortController.signal
            }).then((response) => {
                navigate("/solution",
                    {
                        state: {
                            'solution': response['data']['possible_shifts'],
                            'classesComposition': response['data']['classesComposition'],
                            'teachers': teachers.map(e => e['value'])
                        }
                    })
            }).catch((error) => {
                if (axios.isCancel(error)) {
                    setWaitingForResponse(false)
                } else {
                    navigate("/solution",
                        {
                            state: {
                                'error':error.toString()
                            }
                        })
                }
            })
        }
        let buttonOrProgress
        if (waitingForResponse) {
            buttonOrProgress =
                <Stack direction="column" justifyContent={"center"} spacing={2} alignItems="center">
                    <CircularProgress color="primary" />
                    <Button variant="contained" onClick={(e) => { abortController.abort() }}>
                        annulla
                    </Button>
                    <Typography variant="p">Attendi, la generazione di una pianificazione potrebbe richiedere del tempo...</Typography>
                </Stack>
        } else {
            buttonOrProgress =
                <Button variant="contained" onClick={goOn}>
                    avanti
                </Button>
        }

        return (
                <PaperContainer maxWidth="lg">
                    <Typography variant="h6" textAlign={"center"}>Alcuni passaggi di configurazione...</Typography>
                    <Grid container direction="row" justifyContent={"center"} alignItems={"center"}>
                        <Grid item xs={12}>
                            <MultiSelect
                                options={subjects}
                                value={subjectsToExclude}
                                onChange={setSubjectsToExclude}
                                textBeside={"scegli se escludere qualche materia, in questo modo verranno trascurate le sovrapposizioni dei docenti che insegnano tali materie nella ricerca di una soluzione"}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" >
                        <Grid item xs={12}>
                            <MultiSelect
                                options={teachers}
                                value={teachersToExclude}
                                onChange={setTeachersToExclude}
                                textBeside={"scegli se escludere qualche docente, in questo modo verranno trascurate le sovrapposizioni di quei docenti nella ricerca di una soluzione"}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={12} container justifyContent={"center"}>
                            <FormControl>
                                <RadioGroup
                                    defaultValue="chooseNumberOfDays"
                                    name="radio-buttons-group"
                                    onChange={(e) => {
                                        setmaxPerDayOrNumberOfDays(e.target.value);
                                    }}
                                >
                                    <Grid container direction="row">
                                        <Grid item xs={6}>
                                            <FormControlLabel value="chooseNumberOfDays" control={<Radio />} label={<Typography variant="p">scegli in quanti giorni terminare i consigli di classe</Typography>} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value="chooseMaxPerDay" control={<Radio />} label={<Typography variant="p">scegli il numero massimo di consigli di classe per giorno</Typography>} />
                                    </Grid>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent={"center"}>
                        <Grid item xs={12} container>
                            <IntInput
                                min={1}
                                amx={20}
                                defaultValue={intValue}
                                textBeside={maxPerDayOrNumberOfDays === "chooseMaxPerDay" ? "quanti turni di consigli di classe al giorno?" : "in quanti giorni svolgere i consigli di classe?"}
                                onChange={setIntValue}
                            />
                        </Grid>
                    </Grid>
                    {buttonOrProgress}
                </PaperContainer>
        );
    } catch (error) {
        return <Error error={error.toString()}></Error>
    }
}

export default ConfigureSolver;