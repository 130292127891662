import Select, { createFilter } from 'react-select';
import filterConfig from "./multiSelectFilterConfigAndStyles.js"
import styles from "./multiSelectFilterConfigAndStyles.js"
import { Grid, Typography } from "@mui/material"

function MultiSelect(props) {
    return (
        <div>
            <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="p">{props.textBeside}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Select
                        value={props.value}
                        styles={styles}
                        isMulti
                        isClearable={true}
                        name="escludi materie"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(value, { action, removedValue }) => { props.onChange(value) }}
                        options={props.options}
                        filterOption={createFilter(filterConfig)}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default MultiSelect;