import { Typography, Tabs, Tab } from "@mui/material"
import { useState } from "react";
import PaperContainer from "./PaperContainer";

function HelpAskForExcelFile() {
    const [addressChosen, setAddressChosen] = useState("classico")

    let img = <img src={addressChosen+".png"} alt="immagine d'esempio"></img>
    return <PaperContainer maxWidth="100vh" alignContent="left">
            <Typography variant="h6" textAlign={"center"}>Come deve essere fatto il file Excel?</Typography>
            <Typography variant="p">Il file Excel dovrà avere un foglio per ciascuno degli indirizzi della tua scuola </Typography>
            <Typography variant="p">Ogni foglio dovrà contenere nelle righe le diverse materie e nelle colonne le diverse classi </Typography>
            <Typography variant="p">In ogni cella vi sarà quindi contenuto il docente che insegna tale materia in quella classe </Typography>
            <Typography variant="p">Se in quella classe tale materia non è insegnata, la casella dovrà essere lasciata vuota </Typography>
            <Typography variant="p">Nominare sempre i docenti allo stesso modo. Attenzione alle lettere maiuscole e minuscole: Rossi e rossi saranno considerati due docenti diversi!</Typography>
            <Typography variant="p">Se vi sono più docenti che si dividono le ore di quella materia bisogna inserire due righe per tale materia. Nell'immagine mostrata qui sotto per esempio la classe 2BART del Liceo Scientifico ha due docenti di sostegno: Grassi e Bruni.</Typography>
            <Typography variant="p">Qualora si riporti una classe articolata in più fogli, sarà necessario nominarla all stesso modo nei diversi fogli. La 1BART per esempio è presente sia nel foglio del Liceo Classico che in quello del Liceo Linguistico</Typography>
            <Typography variant="p">{"Qui puoi trovare il "}
                <a href="esempio_consigli_di_classe.xlsx" download> file di esempio</a>
                {" mostrato nell'immagine, per aiutarti a creare quello con i docenti e le classi della tua scuola"}
            </Typography>
            <Tabs value={addressChosen} onChange={(e, value)=>{setAddressChosen(value)}} aria-label="disabled tabs example">
                <Tab value="classico" label="Liceo Classico" />
                <Tab value="scientifico" label="Liceo Scientifico" />
                <Tab value="linguistico" label="Liceo Linguistico" />
            </Tabs>
            {img}   
        </PaperContainer>

}


export default HelpAskForExcelFile;