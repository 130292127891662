import * as XLSX from 'xlsx';

export default function WriteExcelFile(scheduleFound, classesComposition, daysAtSchoolFrequenciesTeachers) {
  // const wb = XLSX.utils.book_new()
  // var ws = XLSX.utils.aoa_to_sheet([
  //   ["S", "h", "e", "e", "t", "J", "S"],
  //   [1, 2, , , 5, 6, 7],
  //   [2, 3, , , 6, 7, 8],
  //   [3, 4, , , 7, 8, 9],
  //   [4, 5, 6, 7, 8, 9, 0]
  // ]);

  // XLSX.utils.book_append_sheet(wb, ws, 'Responses')
  // XLSX.writeFile(wb, 'sampleData.export.xlsx')
  const wb = XLSX.utils.book_new()
  let rows = []
  for (let class_ of Object.keys(classesComposition)) {
    let row = []
    row.push(class_)
    for (let teacher of classesComposition[class_]) {
      if (teacher !== '') {
        row.push(teacher)
      }
    }
    rows.push(row)
  }
  console.log(rows)
  let ws = XLSX.utils.aoa_to_sheet(rows);
  XLSX.utils.book_append_sheet(wb, ws, "composizioni")
  let dayNumber = 1
  for (let day of scheduleFound) {
    let ws = XLSX.utils.aoa_to_sheet(day);
    XLSX.utils.book_append_sheet(wb, ws, "giorno " + dayNumber.toString())
    dayNumber++
  }
  XLSX.writeFile(wb, 'pianificazione.xlsx')
}