/*def get_days_at_school(possible_shifts, classes_teachers, teachers):
    days_at_school = {teacher: 0 for teacher in teachers}
    for day in possible_shifts:
        teachers_this_day = set()
        for shift in day:
            for c in shift:
                teachers = classes_teachers[c]
                teachers_this_day = teachers_this_day.union(teachers)
        for teacher in teachers_this_day:
            days_at_school[teacher] += 1
    return days_at_school
*/
export function getDaysAtSchool(scheduleFound, classesComposition, teachers) {
    let daysAtSchool = {}
    for (let teacher of teachers) {
        daysAtSchool[teacher] = 0
    }
    for (let day of scheduleFound) {
        let teachersThisDay = new Set()
        for (let shift of day) {
            for (let class_ of shift) {
                let teachersThisClass = new Set(classesComposition[class_].filter(e => e !== ''))
                teachersThisDay = new Set([...teachersThisDay, ...teachersThisClass])
            }
        }
        for (let teacher of teachersThisDay) {
            daysAtSchool[teacher]++
        }
    }
    return daysAtSchool
}

/*
def get_days_at_schools_frequencies(days_at_school):
    days_at_school_frequencies = {}
    for teacher, days in days_at_school.items():
        if days_at_school_frequencies.get(days) is None:
            days_at_school_frequencies[days] = 0
        else:
            days_at_school_frequencies[days] += 1
    return days_at_school_frequencies
*/
export function getDaysAtSchoolFrequencies(scheduleFound, classesComposition, teachers) {
    let daysAtSchool = getDaysAtSchool(scheduleFound, classesComposition, teachers)
    let daysAtSchoolFrequencies = []
    let daysAtSchoolFrequenciesTeachers = []
    for (let i = 0; i < Math.max(...Object.values(daysAtSchool)) + 1; i++) {
        daysAtSchoolFrequencies[i] = 0
        daysAtSchoolFrequenciesTeachers = []
    }
    for (let i = 0; i < daysAtSchoolFrequencies.length; i++) {
        daysAtSchoolFrequencies[i] = { 'numero di giorni': i, 'numero di docenti': 0 }
        daysAtSchoolFrequenciesTeachers[i] = []
    }
    for (let teacher of Object.keys(daysAtSchool)) {
        let d = daysAtSchool[teacher]
        daysAtSchoolFrequencies[d]['numero di docenti']++
        daysAtSchoolFrequenciesTeachers[d].push(teacher)
    }
    console.log(daysAtSchoolFrequenciesTeachers)
    return { daysAtSchoolFrequencies, daysAtSchoolFrequenciesTeachers }
}